<template>
  <div class="pop-input">
    <a-modal
        :title="title"
        ok-text="确定"
        cancel-text="取消"
        :visible="visible"
        @cancel="visible = !visible"
    >
      <div class="box">
        <span>{{ inputName }}：</span>
        <a-input :placeholder="`请输入${inputName}`" v-model="data"/>
      </div>
      <template slot="footer">
        <a-button key="back" @click="visible = !visible">
          取消
        </a-button>
        <a-button key="submit" type="primary" @click="handleOk">
          确定
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: '编辑',
      type: String
    },
    inputName: {
      default: '数据',
      type: String
    }
  },
  data() {
    return {
      visible: false,
      data: ''
    }
  },
  methods: {
    handleOk() {
      this.$emit('ok', this.data);

      this.visible = false;
    },

    show(data = '') {
      this.visible = true;
      this.data = data;
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  display: flex;
  align-items: center;

  span {
    white-space: nowrap;
    margin-right: 16px;
  }
}
</style>
