
const app = {
  state: {
    initAgentConfig: false
  },
  mutations: {
    SET_INIT_AGENT_CONFIG: (state, data) => {
      state.initAgentConfig = data
    }
  },
  actions: {
  }
}

export default app
