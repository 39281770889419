
const user = {
  state: {
    dataPermission: sessionStorage.getItem('user_p') || 2,
  },
  mutations: {
    SET_PERMISSION: (state, data) => {
      state.dataPermission = data
    }
  },
  actions: {
    setUesrIPermission ({ commit }, data) {
      sessionStorage.setItem('user_p', data)
      commit('SET_PERMISSION', data)
    }
  }
}

export default user
