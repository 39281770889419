import Vue from 'vue'
import VueRouter from 'vue-router'
import { checkLogin } from '@/plugins/utils'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "index" */ '@/views/index/index')
  },
  {
    path: '/index/create',
    component: () => import(/* webpackChunkName: "index" */ '@/views/index/create')
  },
  {
    path: '/contact/index',
    meta: {
      keepAlive: true
    },
    component: () => import(/* webpackChunkName: "contact" */ '@/views/contact/index')
  },
  {
    path: '/contact/screen',
    component: () => import(/* webpackChunkName: "contact" */ '@/views/contact/screen')
  },
  {
    path: '/contact/detail',
    component: () => import(/* webpackChunkName: "contact" */ '@/views/contact/detail')
  },
  {
    path: '/couponWrite',
    component: () => import(/* webpackChunkName: "couponWrite" */ '@/views/couponWrite/index')
  },
  {
    path: '/participate',
    meta: {
      keepAlive: true
    },
    component: () => import(/* webpackChunkName: "couponWrite" */ '@/views/participate/index')
  },
  {
    path: '/participate/joinMemberList',
    component: () => import(/* webpackChunkName: "couponWrite" */ '@/views/participate/components/joinMemberList')
  },
  {
    path: '/participate/joinLeaveInfoList',
    component: () => import(/* webpackChunkName: "couponWrite" */ '@/views/participate/components/joinLeaveInfoList')
  },
  {
    path: '/participateData',
    component: () => import(/* webpackChunkName: "participateData" */ '@/views/participate/components/employeeData')
  },
  {
    path: '/my',
    component: () => import(/* webpackChunkName: "my" */ '@/views/my/index')
  },
  {
    path: '/my/contact',
    component: () => import(/* webpackChunkName: "my" */ '@/views/my/contact')
  },
  {
    path: '/my/feedBack',
    component: () => import(/* webpackChunkName: "my" */ '@/views/my/feedBack')
  },
  {
    path: '/tool/index',
    component: () => import(/* webpackChunkName: "tool" */ '@/views/tool/index')
  },
  {
    path: '/contactStatistic/index',
    component: () => import(/* webpackChunkName: "contact" */ '@/views/contactStatistic/index')
  },
  {
    path: '/employeeContactStatistics',
    component: () => import(/* webpackChunkName: "contact" */ '@/views/employeeContactStatistics/index')
  },
  {
    path: '/channelCode/index',
    component: () => import(/* webpackChunkName: "channelCode" */ '@/views/channelCode/index')
  },
  {
    path: '/channelCode/create',
    component: () => import(/* webpackChunkName: "channelCode" */ '@/views/channelCode/create')
  },
  {
    path: '/channelCode/detail',
    component: () => import(/* webpackChunkName: "channelCode" */ '@/views/channelCode/detail')
  },
  {
    path: '/contactWelcome/index',
    component: () => import(/* webpackChunkName: "contactWelcome" */ '@/views/contactWelcome/index')
  },
  {
    path: '/contactWelcome/create',
    component: () => import(/* webpackChunkName: "contactWelcome" */ '@/views/contactWelcome/create')
  },
  {
    path: '/workMoment/index',
    component: () => import(/* webpackChunkName: "workMoment" */ '@/views/workMoment/index')
  },
  {
    path: '/workMoment/create',
    component: () => import(/* webpackChunkName: "workMoment" */ '@/views/workMoment/create')
  },
  {
    path: '/medium/index',
    component: () => import(/* webpackChunkName: "medium" */ '@/views/medium/index')
  },
  {
    path: '/medium/detail',
    component: () => import(/* webpackChunkName: "medium" */ '@/views/medium/detail')
  },
  {
    path: '/medium/create',
    component: () => import(/* webpackChunkName: "medium" */ '@/views/medium/create')
  },
  {
    path: '/roomTemplatePull/index',
    component: () => import(/* webpackChunkName: "roomTemplatePull" */ '@/views/roomTemplatePull/index')
  },
  {
    path: '/roomTemplatePull/create',
    component: () => import(/* webpackChunkName: "roomTemplatePull" */ '@/views/roomTemplatePull/create')
  },
  {
    path: '/contactDeleteRemind/index',
    component: () => import(/* webpackChunkName: "contactDeleteRemind" */ '@/views/contactDeleteRemind/index')
  },
  {
    path: '/contactLost/index',
    component: () => import(/* webpackChunkName: "contactLost" */ '@/views/contactLost/index')
  },
  {
    path: '/sensitiveWordMonitor/index',
    component: () => import(/* webpackChunkName: "sensitiveWordMonitor" */ '@/views/sensitiveWordMonitor/index')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login')
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth')
  },
  {
    path: '/contactMessageBatchSend/index',
    component: () => import(/* webpackChunkName: "contactMessageBatchSend" */ '@/views/contactMessageBatchSend/index')
  },
  {
    path: '/contactMessageBatchSend/create',
    component: () => import(/* webpackChunkName: "contactMessageBatchSend" */ '@/views/contactMessageBatchSend/create')
  },
  {
    path: '/contactMessageBatchSend/details',
    component: () => import(/* webpackChunkName: "contactMessageBatchSend" */ '@/views/contactMessageBatchSend/details')
  },
  {
    path: '/roomMessageBatchSend/index',
    component: () => import(/* webpackChunkName: "roomMessageBatchSend" */ '@/views/roomMessageBatchSend/index')
  },
  {
    path: '/roomMessageBatchSend/details',
    component: () => import(/* webpackChunkName: "roomMessageBatchSend" */ '@/views/roomMessageBatchSend/details')
  },
  {
    path: '/roomMessageBatchSend/create',
    component: () => import(/* webpackChunkName: "roomMessageBatchSend" */ '@/views/roomMessageBatchSend/create')
  },
  {
    path: '/naire/index',
    component: () => import(/* webpackChunkName: "roomMessageBatchSend" */ '@/views/naire/index')
  },
  {
    path: '/naire/detail',
    component: () => import(/* webpackChunkName: "roomMessageBatchSend" */ '@/views/naire/detail')
  },
  {
    path: '/contactTodo/index',
    component: () => import(/* webpackChunkName: "contactTodo" */ '@/views/contactTodo/index')
  },
  {
    path: '/contactSop/index',
    component: () => import(/* webpackChunkName: "contactSop" */ '@/views/contactSop/index')
  },
  {
    path: '/silenceSop/index',
    component: () => import(/* webpackChunkName: "silenceSop" */ '@/views/silenceSop/index')
  },
  {
    path: '/departmentLoss/index',
    component: () => import(/* webpackChunkName: "departmentLoss" */ '@/views/departmentLoss/index')
  },
  {
    path: '/employeeRanking/index',
    component: () => import(/* webpackChunkName: "employeeRanking" */ '@/views/employeeRanking/index')
  },
  {
    path: '/stageAudit/index',
    component: () => import(/* webpackChunkName: "stageAudit" */ '@/views/stageAudit/index')
  },
  {
    path: '/stageAudit/employeeAudit',
    component: () => import(/* webpackChunkName: "employeeAudit" */ '@/views/stageAudit/employeeAudit')
  },
  {
    path: '/replyMonitor',
    component: () => import(/* webpackChunkName: "replyMonitor" */ '@/views/replyMonitor/index')
  },
  {
    path: '/contactTask',
    component: () => import(/* webpackChunkName: "contactTask" */ '@/views/contactTask/index')
  },
  {
    path: '/contactTask/employee',
    component: () => import(/* webpackChunkName: "contactTask" */ '@/views/contactTask/employee')
  },
  {
    path: '/contactTask/contact',
    component: () => import(/* webpackChunkName: "contactTask" */ '@/views/contactTask/contact')
  },
  {
    path: '/contactFollow/waitList',
    component: () => import(/* webpackChunkName: "waitList" */ '@/views/contactFollow/waitList')
  },
  {
    path: '/waitFollowTip',
    component: () => import(/* webpackChunkName: "waitFollowTip" */ '@/views/waitFollowTip/index')
  },
  {
    path: '/waitFollowTip/contact',
    component: () => import(/* webpackChunkName: "waitFollowTipcontact" */ '@/views/waitFollowTip/components/contact')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach(async (to, from, next) => {
  try {
    if (checkLogin(to, from, next) === false) {
      let agentId = to.query.agentId

      if (!agentId) {
        agentId = getCookie('workbench_agentId')
      }
      next({ path: '/login', query: { agentId: agentId, target: to.fullPath } })
    } else {
      next()
    }
  } catch (e) {
    next({ path: '/' })
  }
})

export default router
